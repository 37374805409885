<template>
  <div id="calendar" v-show="!loading">
    <vx-card class="p-2 mb-4">
      <div class="vx-row mb-2">
        <!-- Datepicker -->
        <div class="vx-col w-full md:w-6/12 px-0">
          <div class="vx-row">
            <div class="vx-col w-full md:w-6/12 px-2">
              <span class="block mb-2">Select Dates</span>
              <flat-pickr
                class="w-full"
                :config="calendarDatesConfig"
                v-model="calendarDate"
                @on-change="getData"
              />
            </div>
          </div>
        </div>

        <!-- Room select -->
        <div class="vx-col w-full md:w-6/12 px-0">
          <div class="vx-row flex justify-end">
            <div class="vx-col w-full md:w-8/12 px-2">
              <span class="block mb-2">Select Room</span>
              <v-select
                label="name"
                v-model="filterRoom"
                :options="roomOptions"
                :reduce="(room) => room.id"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                @input="getData"
              />
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <div class="calendar-header-sticky">
      <!-- Dates -->
      <div class="table-horizontal-container linked" id="dates">
        <table class="vancy-calendar-table">
          <thead>
            <tr>
              <th class="text-lg font-medium">Dates</th>
              <th
                v-for="(date, i) in calendar"
                :key="i"
                :class="[
                  $moment(date).format('ddd') ==
                  $moment(date).startOf('week').format('ddd')
                    ? 'text-danger'
                    : '',
                ]"
              >
                {{ $moment(date).format("ddd") }}
                <br />
                {{ date }}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <div class="table-horizontal-container linked" id="room-lists">
      <div class="room-container" v-for="(room, i) in rooms" :key="i">
        <div class="room-name">
          <b>{{ room.name }}</b> (Room ID: {{ room.id }})
        </div>
        <table class="vancy-calendar-table">
          <tbody>
            <!-- Room status -->
            <tr>
              <th>Room Status</th>
              <td
                v-for="(date, i) in calendar"
                :key="i"
                @click="generatePayload(room, date, 'status')"
              >
                <!-- If have a schedule in this day -->
                <vs-chip
                  class="w-full cursor-pointer select-none"
                  :color="
                    getSchedule(room.schedules, date).bookable
                      ? 'success'
                      : 'danger'
                  "
                  v-if="getSchedule(room.schedules, date)"
                >
                  {{
                    getSchedule(room.schedules, date).bookable
                      ? "Bookable"
                      : "Closed"
                  }}
                </vs-chip>
              </td>
            </tr>

            <!-- Room to sell -->
            <tr id="room-to-sell">
              <th>
                Rooms to Sell
                <el-button class="float-right"
                           type="primary"
                           size="mini"
                           icon="el-icon-document-copy"
                           @click="openBatchRoomToSell(room)"></el-button>
                <el-dialog
                  title="Batch Room to Sell"
                  v-if="isBatchRoomToSellOpen"
                  :visible.sync="isBatchRoomToSellOpen"
                  modal-append-to-body
                  append-to-body
                  width="30%">
                  <h4 class="mb-4">Room: {{ batchRoom.name }}</h4>
                  <span class="block mb-2">Select Start Date</span>
                  <flat-pickr
                    class="w-full"
                    :config="calendarDatesConfigs"
                    v-model="startDateBatch"
                  />

                  <div class="my-4"></div>

                  <span class="block mb-2">Select End Date</span>
                  <flat-pickr
                    class="w-full"
                    :config="calendarDatesConfigs"
                    v-model="endDateBatch"
                  />

                  <div class="my-4"></div>
                  <span class="block mb-2">Room to sell</span>
                  <el-input-number v-model="batchRoomToSell"
                                   :min="1"></el-input-number>


                  <el-button class="w-full mt-6"
                             type="primary"
                             size="mini"
                             icon="el-icon-document-copy"
                             @click="submitBatchRoomToSell">Submit</el-button>
                </el-dialog>
              </th>
              <td
                v-for="(date, i) in calendar"
                :key="i"
                @keyup.esc="resetPayload"
                @click="generatePayload(room, date, 'roomToSell')"
              >
                <span
                  class="block text-center"
                  v-if="
                    payload.roomId !== room.id ||
                    payload.date !== $moment(date).format('YYYY-MM-DD') ||
                    !payload.hasOwnProperty('roomToSell') ||
                    editMode !== 'roomToSell'
                  "
                >
                  {{ getSchedule(room.schedules, date).roomToSell }}
                </span>
                <vs-input
                  autofocus
                  type="number"
                  :max="room.qty"
                  min="0"
                  class="block w-full"
                  pattern="[0-9.]+"
                  v-if="
                    payload.date === $moment(date).format('YYYY-MM-DD') &&
                    payload.roomId === room.id &&
                    payload.hasOwnProperty('roomToSell') &&
                    editMode === 'roomToSell'
                  "
                  v-model="payload.roomToSell"
                  @keyup.enter="generatePayload(room, date, 'roomToSellSave')"
                  @keyup="handleRoomSellInput($event, room)"
                />
              </td>
            </tr>

            <!-- Net Book -->
            <tr id="net-book">
              <th>Net Book</th>
              <td
                v-for="(date, i) in calendar"
                :key="i"
              >

                <span
                  class="block text-center"
                  v-if="payload.roomId !== room.id ||
                      payload.date !== $moment(date).format('YYYY-MM-DD') &&
                      getSchedule(room.schedules, date)
                  ">
                  {{ getSchedule(room.schedules, date).roomBooked }}
                </span>

<!--                <vs-input-->
<!--                  autofocus-->
<!--                  type="number"-->
<!--                  min="0"-->
<!--                  class="block w-full"-->
<!--                  v-model="payload.netBook"-->
<!--                  v-if="-->
<!--                    payload.roomId === room.id &&-->
<!--                    payload.date === $moment(date).format('YYYY-MM-DD') &&-->
<!--                    payload.hasOwnProperty('netBook')-->
<!--                  "-->
<!--                  @keyup="handleNetBookInput($event, room.schedules, date)"-->
<!--                />-->
              </td>
            </tr>

            <!-- Rate Plan -->
            <tr v-for="(ratePlan, i) in room.ratePlans" :key="i">
              <th>
                <span class="block text-sm"> - {{ ratePlan.name }} </span>
                <div
                  class="promo-list"
                  v-for="(promo, i) in ratePlan.promos"
                  :key="i"
                >
                  <vs-divider v-show="ratePlan.promos.length > 0"></vs-divider>
                  <span class="block text-sm"> (%) {{ promo.name }} </span>
                </div>
              </th>
              <td
                v-for="(date, i) in calendar"
                :key="i"
                @click="generateRatePlanPricePayload(room, ratePlan, date)"
                @keyup.esc="resetPayload"
              >
                <!-- Default price -->
                <div class="price-default">
                  <span
                    class="block text-center py-3"
                    v-if="
                      payload.roomId !== room.id ||
                      payload.date !== $moment(date).format('YYYY-MM-DD') ||
                      payload.ratePlanId !== ratePlan.id
                    "
                  >
                    {{
                      getRatePlanSchedule(room.schedules, ratePlan, date)
                        ? priceFormater(
                            getRatePlanSchedule(room.schedules, ratePlan, date)
                              .netPrice
                          )
                        : ""
                    }}
                  </span>

                  <vs-input
                    autofocus
                    class="block w-full"
                    type="number"
                    :min="ratePlan.minimumPrice"
                    v-if="
                      payload.date === $moment(date).format('YYYY-MM-DD') &&
                      payload.roomId === room.id &&
                      payload.ratePlanId === ratePlan.id &&
                      editMode === 'ratePlanEdit'
                    "
                    v-model="payload.netPrice"
                    @keyup.enter="storeSchedule"
                  />
                </div>

                <!-- Promo price list -->
                <div
                  class="price-promo"
                  v-show="
                    ratePlan.promos.length > 0 &&
                    getRatePlanSchedule(room.schedules, ratePlan, date)
                  "
                  v-for="(promo, i) in ratePlan.promos"
                  :key="i"
                >
                  <vs-divider
                    v-show="
                      ratePlan.promos.length > 0 &&
                      getRatePlanSchedule(room.schedules, ratePlan, date)
                    "
                  ></vs-divider>
                  <span class="block text-center">
                    {{
                      getDiscountPrice(
                        getRatePlanSchedule(room.schedules, ratePlan, date),
                        promo
                      )
                    }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { debounce } from '@/globalComponents'
import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "VancyNetRatePlanCalendar",

  metaInfo: {
    title: "Rate Plan Calendar",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    flatPickr,
    "v-select": vSelect,
  },

  watch: {
    calendarDate(date) {
      let startDate;
      let endDate;
      if (Array.isArray(date)) {
        startDate = date[0];
        endDate = date[1];
      } else {
        const dateArray = date.split(" to ");
        startDate = dateArray[0];
        endDate = dateArray[1];
      }

      if (startDate !== undefined && endDate !== undefined) {
        // generate calendar again
        this.startDate = this.$moment(startDate);
        this.endDate = this.$moment(endDate);
        this.generateCalendar(this.startDate, this.endDate);
      }

      this.calendarDatesConfig.maxDate = this.$moment(startDate)
        .add(this.$moment(startDate).endOf("month").format("DD"), "day")
        .format("YYYY-MM-DD");
    },
  },

  data() {
    return {
      rooms: [],
      isBatchRoomToSellOpen: false,
      batchRoom: null,
      batchRoomToSell: 0,
      startDateBatch: new Date(),
      endDateBatch: this.$moment().add(
        1,
        "M"
      ).toDate(),

      // Dates settings
      startDate: this.$moment(),
      endDate: this.$moment().add(
        this.$moment().endOf("month").format("DD"),
        "day"
      ),
      calendar: [],
      calendarDate: "",
      filterRoom: null,

      payload: {
        roomId: null,
        ratePlanId: null,
        date: null,
      },
      payloadRoomToSell: [],

      loading: true,
      isSaving: false,
      editMode: '',

      // flatPickr config
      calendarDatesConfig: {
        mode: "range",
        dateFormat: "Y-m-d",
        minDate: null,
        maxDate: null,
      },

      calendarDatesConfigs: {
        mode: "single",
        dateFormat: "Y-m-d",
        minDate: this.$moment().subtract(1, 'day').toDate(),
        maxDate: null,
      },

      roomOptions: [],
      statusOptions: [
        {
          id: 0,
          name: "Closed",
        },
        {
          id: 1,
          name: "Bookable",
        },
      ],
    };
  },

  computed: {
    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    },
  },

  methods: {
    fixedScroll() {
      Array.from(document.querySelectorAll(".linked")).forEach((item) => {
        item.addEventListener("scroll", function () {
          Array.from(document.querySelectorAll(".linked"))
            .filter(
              (table) => table.getAttribute("id") !== item.getAttribute("id")
            )
            .forEach((table) => {
              table.scrollLeft = item.scrollLeft;
            });
        });
      });
    },

    priceFormater(price) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      })
        .format(price)
        .split(",")[0];
    },

    handleRoomSellInput(e, room) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        if (this.payload.roomToSell > room.qty) {
          this.payload.roomToSell = room.qty;
        }
      }
    },

    handleNetBookInput(e, schedules, date) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        const schedule = this.getSchedule(schedules, date);

        if (this.payload.netBook > schedule.roomToSell) {
          this.payload.netBook = schedule.roomToSell;
        }
      }
    },

    generateCalendar(startDate, endDate) {
      this.calendar = [];

      while (startDate.isSameOrBefore(endDate)) {
        this.calendar.push(startDate.format("YYYY-MM-DD"));
        startDate.add(1, "day");
      }
    },

    generateRatePlanPricePayload(room, ratePlan, date) {
      const schedule = this.getRatePlanSchedule(room.schedules, ratePlan, date)
      this.editMode = 'ratePlanEdit';
      this.payload = {
        roomId: room.id,
        ratePlanId: ratePlan.id,
        date: this.$moment(date).format("YYYY-MM-DD"),
        bookable: schedule.bookable,
        netPrice: schedule.netPrice,
        roomToSell: schedule.roomToSell,
      };
    },

    async generatePayload(room, date, payloadFor) {
      const bookDay = this.getSchedule(room.schedules, date);
      const bookable = bookDay ? !bookDay.bookable : true;
      const netPrice = bookDay?.netPrice ?? bookDay.minimumPrice;
      const roomToSell = bookDay ? bookDay.roomToSell : 0;
      this.editMode = payloadFor;
      switch (payloadFor) {
        case "status":
          for (const ratePlan of room.ratePlans) {
            this.payload = {
              roomId: parseInt(room.id),
              ratePlanId: ratePlan.id,
              date: new Date(date),
              netPrice: !ratePlan.netPrice ? ratePlan.minimumPrice : ratePlan.netPrice,
              bookable,
            };
            await this.storeSchedule();
          }
          break;

        case "roomToSell":
          this.payload = {
            roomId: parseInt(room.id),
            date: this.$moment(date).format("YYYY-MM-DD"),
            netPrice,
            bookable: bookDay.bookable,
            roomToSell,
          };
        break;

        case "roomToSellSave":
          for (const ratePlan of room.ratePlans) {
            const schedule = this.getRatePlanSchedule(room.schedules, ratePlan, date);
            this.payload = {
              roomId: room.id,
              ratePlanId: ratePlan.id,
              netPrice: schedule.netPrice,
              bookable: bookDay.bookable,
              date: this.$moment(date).format("YYYY-MM-DD"),
              roomToSell: this.payload.roomToSell,
            };
            await this.storeSchedule();
          }
          break;

        case "netBook":
          this.payload = {
            roomId: room.id,
            date: this.$moment(date).format("YYYY-MM-DD"),
            netBook: 0,
          };
          break;
      }
    },

    resetPayload() {
      this.payload.roomId = null;
      this.payload.ratePlanId = null;
      this.payload.date = null;
    },

    getSchedule(schedules, date) {
      const schedule = schedules.find(
        (schedule) => date === this.$moment(schedule.date).format("YYYY-MM-DD")
      );

      return schedule ? schedule : 0;
    },

    getRatePlanSchedule(schedules, ratePlan, date) {
      const schedule = schedules
        .filter(
          (schedule) => schedule.ratePlanId === ratePlan.id
        )
        .find(
          (schedule) =>
            this.$moment(date).format("YYYY-MM-DD") === this.$moment(schedule.date).format("YYYY-MM-DD")
        )
      return schedule ? schedule : 0;
    },

    getDiscountPrice(schedule, promo) {
      if (schedule) {
        if (
          this.$moment(schedule.date).isBetween(
            promo.bookStartDate,
            promo.bookEndDate,
            undefined,
            "[]"
          )
        ) {
          const discountPrice =
            schedule.netPrice * (promo.discount.slice(0, -1) / 100);
          return this.priceFormater(schedule.netPrice - discountPrice);
        }

        return "-";
      }
    },

    getData() {
      this.$vs.loading({
        type: "sound",
      });

      const params = {
        start: this.calendarDate[0],
        end: this.calendarDate[1],
      }

      axios
        .get(`hotels/${this.hotelId}/rate-schedules`, { params })
        .then(({ data: res }) => {
          res.data = res.data.map((room) => ({
            ...room,
            id: parseInt(room.id),
            schedules: room.schedules.filter(r => r.roomId === parseInt(room.id)),
          }))
          this.roomOptions = res.data.map((room) => {
            return {
              id: parseInt(room.id),
              name: room.name,
              minPrice: room.minPrice,
            };
          });
          this.roomOptions.unshift({ id: 0, name: "All Room" });

          if (!this.filterRoom) {
            this.rooms = res.data;
          } else {
            this.rooms = res.data.filter((room) => room.id === this.filterRoom);
          }
        })
        .catch((err) => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false;
          this.$vs.loading.close();
        });
    },

    async storeSchedule() {
      if (this.payload.netPrice > 0) {
        const roomDetail = this.roomOptions.filter(
          (room) => room.id === this.payload.roomId
        )[0];

        if (parseInt(this.payload.netPrice) < parseInt(roomDetail.minimumPrice)) {
          this.$vs.notify({
            title: "Error",
            text: "Price Less than min Price",
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "danger",
          });
          return;
        }
      }
      this.$vs.loading({
        type: "sound",
      });

      try {
        const body = {
          ...this.payload,
          netPrice: parseInt(this.payload.netPrice),
          date: new Date(this.payload.date),
          roomId: parseInt(this.payload.roomId),
          roomToSell: parseInt(this.payload.roomToSell),
        }

        const res = await axios
          .post(`hotels/${this.hotelId}/rate-schedules`, body);
        if (!this.isSaving) {
          this.isSaving = true;
          this.$vs.notify({
            title: "Successful",
            text: res.message,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
            group: true,
          });
          setTimeout(() => {
            this.isSaving = false;
          }, 2000)
        }
        this.editMode = '';
        this.resetPayload();
      } catch (e) {
        this.$catchErrorResponse(e)
      } finally {
        this.$vs.loading.close();
        this.getData();
      }
    },

    openBatchRoomToSell(room) {
      this.isBatchRoomToSellOpen = true;
      this.batchRoom = room;
    },
    getRange(startDate, endDate, type) {
      let fromDate = this.$moment(startDate)
      let toDate = this.$moment(endDate)
      let diff = toDate.diff(fromDate, type)
      let range = []
      for (let i = 0; i <= diff; i++) {
        range.push(this.$moment(startDate).add(i, type).toDate())
      }
      return range
    },
    async submitBatchRoomToSell() {
      const range = this.getRange(this.startDateBatch, this.endDateBatch, 'days');
      for (const ratePlan of this.batchRoom.ratePlans) {
        for (const date of range) {
          const schedule = this.getRatePlanSchedule(this.batchRoom.schedules, ratePlan, date);
          this.payload = {
            roomId: this.batchRoom.id,
            ratePlanId: ratePlan.id,
            netPrice: schedule.netPrice,
            bookable: true,
            date: this.$moment(date).format("YYYY-MM-DD"),
            roomToSell: this.batchRoomToSell,
          };
          await this.storeSchedule();
        }
      }
      this.isBatchRoomToSellOpen = false;
    }
  },

  mounted() {
    this.fixedScroll();
    this.generateCalendar(this.startDate, this.endDate);
  },

  created() {
    this.calendarDate = [
      this.$moment().format("YYYY-MM-DD"),
      this.$moment()
        .add(this.$moment().endOf("month").format("DD"), "day")
        .format("YYYY-MM-DD"),
    ];
    this.getData();
  },
};
</script>

<style lang="scss">
#calendar .calendar-header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 82px;
  padding-top: 0px;
  z-index: 10;
  background: #f8f8f8;
}

.vancy-calendar-table {
  border-collapse: separate;
  border-spacing: 0;
  font-family: helvetica;
}

.vancy-calendar-table td,
th {
  border: 1px solid #e0e0e0;
  padding: 10px;
  min-width: 120px;
  background: white;
  box-sizing: border-box;
  text-align: left;
  z-index: -10;
  font-size: 12px;
}

.table-horizontal-container {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.vancy-calendar-table thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: center;
}

.room-title {
  position: absoulute;
  left: 0;
  z-index: 3;
}

.vancy-calendar-table thead th:first-child {
  left: 0;
  z-index: 3;
  min-width: 200px;
}

.vancy-calendar-table tfoot {
  bottom: 0;
  z-index: 2;
}

.vancy-calendar-table tfoot td {
  bottom: 0;
  z-index: 2;
  background: hsl(20, 50%, 70%);
}

.vancy-calendar-table tfoot td:first-child {
  z-index: 3;
}

.vancy-calendar-table tbody {
  overflow: scroll;
  height: 200px;
}

.vancy-calendar-table tr > :first-child {
  position: -webkit-sticky;
  position: sticky;
  min-width: 200px;
  left: 0;
  z-index: 3;
  font-weight: normal;
  background: #f5f5f5;
}

.room-container {
  display: unset;
}

.room-name {
  border: 1px solid #e0e0e0;
  width: 100%;
  padding: 10px;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  background: #fff;
  font-size: 12px;
}

tr#room-to-sell input {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .room-name {
    margin-top: 10px;
  }

  .room-name ~ .room-name {
    margin-top: 0px;
  }
}
#calendar {
  .vx-card .vx-card__collapsible-content .vx-card__body {
    padding: .5rem !important;
  }
}
</style>
